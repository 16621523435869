@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap";
.alignleft {
  float: left;
  margin-right: 15px;
}

.alignright {
  float: right;
  margin-left: 15px;
}

.aligncenter {
  margin: 0 auto 15px;
  display: block;
}

a:focus {
  outline: 0 solid;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  margin: 0 0 15px;
}

body {
  color: #000;
  background: #ffdb9c fixed;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14pt;
  font-weight: 400;
}

#canvas-basic {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

a:hover {
  text-decoration: none;
}

.menu-close {
  cursor: pointer;
  transition: all .4s;
}

.menu-close:hover {
  color: #888;
}

.offcanvas-area {
  height: 100%;
  width: 300px;
  z-index: 7;
  visibility: hidden;
  -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .25);
  -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .25);
  box-shadow: 5px 5px 5px 5pxx rgba(0, 0, 0, .25);
  background: #f7f7f7;
  transition: all .2s;
  position: fixed;
  right: -100%;
}

.offcanvas-area hr {
  color: gray;
  height: 1px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 0;
}

.offcanvas-area.active {
  visibility: visible;
  right: 0;
}

.offcanvas-area .offcanvas-menu-row {
  margin-top: 12.5pt;
  margin-bottom: 12.5pt;
  margin-left: 10pt;
  font-size: 18pt;
}

.offcanvas-area .offcanvas-menu a {
  color: #3d424e;
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration-thickness: calc(1em / 6.66667);
  transition: all .3s;
}

.offcanvas-area .offcanvas-menu-row .menu-icon {
  float: right;
  margin-right: 22px;
}

.offcanvas-area .offcanvas-menu a:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-thickness: calc(1em / 6.66667);
}

.offcanvas-overlay {
  width: 100%;
  height: 100%;
  visibility: hidden;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 6;
  position: fixed;
}

.offcanvas-overlay.active {
  visibility: visible;
}

.menu-open {
  color: #191718;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 8px;
  font-size: 27px;
  transition: all .3s;
  display: none;
  position: relative;
  top: 1px;
}

.menu-open.hidden {
  visibility: hidden;
}

.menu-open:hover {
  color: #888;
}

.header__area {
  width: 100%;
  height: auto;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0);
  padding-top: 85px;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.header__area.active {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, .15);
  padding-top: 33px;
  padding-bottom: 33px;
}

.header__area img {
  width: auto;
  height: auto;
  max-width: 380px;
  margin-left: 25px;
  transition: all .3s ease-in-out;
}

.header__area.active img {
  width: auto;
  height: auto;
  max-width: 184px;
}

.main___menu ul {
  justify-content: flex-end;
  align-items: center;
  margin: 0 25px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.main___menu ul li a {
  color: #000;
  margin-left: 100px;
  font-size: 13pt;
  font-weight: 400;
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration-thickness: calc(1em / 6.66667);
  transition: all .7s;
  display: inline-block;
}

.main___menu ul li a:hover {
  text-decoration: underline;
  text-decoration-thickness: calc(1em / 6.66667);
}

.container--diff {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
}

.hero__area {
  padding: 360px 25px 210px;
}

.hero__area h3 {
  padding-top: 7px;
  font-size: 30pt;
  font-weight: 200;
  line-height: 1.4;
}

.hero__area p {
  float: right;
  padding-top: 7px;
  padding-left: 45px;
  font-size: 16pt;
  font-weight: 300;
}

.founder__area {
  padding-top: 240px;
  padding-bottom: 40px;
}

.founder__area .team__image {
  width: 75%;
  float: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  display: block;
}

.founder__area .team__card {
  padding-bottom: 30px;
}

.founder__area .team__card__visible {
  cursor: pointer;
}

.founder__area .team__card .team__name {
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  line-height: 1em;
}

.founder__area .team__card .team__title {
  text-align: center;
  letter-spacing: .8pt;
  font-size: 12px;
  font-weight: 800;
  line-height: 1.5em;
}

.team__info__overlay {
  width: 45%;
  height: 100%;
  z-index: 7;
  visibility: hidden;
  -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .25);
  -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .25);
  box-shadow: 5px 5px 5px 5pxx rgba(0, 0, 0, .25);
  background: #f7f7f7;
  padding-bottom: 40px;
  transition: all .2s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.team__info__overlay.active {
  visibility: visible;
  right: 0;
}

.overlay__close {
  width: 36px;
  height: 36px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  background: #fff;
  border-radius: 50%;
  padding: 0;
  font-size: 24px;
  position: fixed;
  top: 10px;
  right: 10px;
  box-shadow: 0 1px 5px -2pt #000;
}

button {
  width: 30px;
  height: 30px;
  color: #00f;
  border: 2px solid #00f;
  border-radius: 50%;
  padding: .5em;
  position: relative;
}

button:hover {
  color: #fff;
  background-color: #ff0;
  border: 2px solid #00f;
}

button:before {
  content: " ";
  width: 2px;
  background-color: #00f;
  display: block;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 12px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

button:after {
  content: " ";
  height: 2px;
  background-color: #00f;
  display: block;
  position: absolute;
  top: 12px;
  left: 5px;
  right: 5px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.team__info__overlay .team__overlay__body {
  width: 90%;
  margin: auto;
  padding-top: 30px;
}

.team__info__overlay p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7em;
}

.team__info__overlay p.team__paragraph_emphasis {
  font-size: 18px;
}

.team__info__overlay .team__photo__overlay {
  width: 100%;
}

.team__info__overlay .team__name__overlay {
  font-size: 24px;
  font-weight: 200;
}

.team__info__overlay .team__title__overlay {
  letter-spacing: .8pt;
  font-size: 12px;
  font-weight: 800;
}

.grow__area {
  background-color: #f4f3ef;
  padding: 120px 200px 120px 230px;
}

.grow__area .common__btn a {
  align-content: right;
  width: 380px;
  margin-left: 0;
}

.grow__area p {
  font-size: 12pt;
  font-weight: 200;
}

.grow__area .right-column {
  margin: auto;
  padding-left: 80px;
}

.grow__area .left-column {
  padding-right: 80px;
  display: block;
}

.grow__area h2 {
  padding-bottom: 5px;
  font-size: 30pt;
  font-weight: 200;
}

.investor__area {
  background-color: #fff;
  padding: 100px 25px;
}

.investor__area .right-column {
  width: 40%;
  margin: auto;
}

.investor__area ul {
  margin-bottom: 0;
  padding-left: 0;
  font-size: 12pt;
  font-weight: 200;
  line-height: 50px;
  list-style: none;
}

.investor__area h2 {
  text-align: center;
  font-size: 16pt;
  font-weight: 200;
  line-height: 1.5;
}

.container--diff {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
}

.credo__area {
  width: 60%;
  margin: auto;
  padding-top: 240px;
  padding-bottom: 100px;
  font-weight: 200;
}

.credo__area h3 {
  padding-bottom: 1px;
  font-size: 36pt;
  font-weight: 200;
  line-height: 1.4;
}

.credo__area p {
  text-align: left;
  padding-top: 7px;
  font-size: 20pt;
}

.credo__area ul li {
  text-align: left;
  margin-left: 4em;
  margin-right: 4em;
  padding-top: 10px;
  font-size: 14pt;
}

.portfolio__area {
  background: #f4f3ef;
  margin: auto;
  padding-top: 270px;
  padding-bottom: 170px;
  font-weight: 400;
}

.portfolio__area__content {
  max-width: 70%;
}

.tonic__area {
  background: #fff;
  margin: auto;
  padding-top: 240px;
  font-weight: 400;
}

.tonic__area__content {
  max-width: 80%;
}

.tonic-images {
  padding-top: 94px;
}

.tonic__area hr {
  width: 90%;
  margin: 94px auto;
}

.founder-section {
  width: 95%;
  margin: auto;
}

.image-container {
  flex-direction: row;
  margin-bottom: 0;
  padding-bottom: 0;
  display: flex;
}

.tonic-img1 {
  flex: 1.01156;
}

.tonic-space2 {
  background-color: #000;
  flex-direction: column;
  flex: 1.04236;
  align-content: center;
  justify-content: center;
  display: flex;
}

.space2-text {
  max-width: 62%;
  text-align: justify;
  color: #fff;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 300;
  line-height: 194%;
}

.space2-text span {
  display: inline-block;
}

.space2-text a {
  color: inherit;
}

.tonic-img3 {
  flex: 1.54777;
}

.tonic-img4 {
  flex: 1;
}

.image-container img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.tonic__area .founder-description * {
  width: 95%;
  margin: auto;
}

.tonic__area .founder-description h1 {
  margin-bottom: 17px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}

.tonic__area .founder-description h2 {
  margin-bottom: 17px;
  padding-top: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.tonic__area .founder-description p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.tonic__area .investors {
  padding-left: 48px;
}

.founder-img {
  width: 80%;
}

.company-logo {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tonic__area__content .company-logo img {
  max-width: 50%;
  max-height: 100%;
}

.tonic__area__content h2 {
  color: #000;
  padding-top: 16px;
  font-size: 28px;
  font-style: normal;
  font-weight: 200;
  line-height: 160%;
}

.tonic__area__content .pad-right-col {
  padding-left: 1%;
  padding-right: 7%;
}

.tonic__area__content .social-info {
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
}

.no-decoration, .no-decoration:hover, .no-decoration:focus, .no-decoration:active {
  color: inherit;
  text-decoration: none;
}

.container--diff {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 15px;
}

.after__hours__area {
  background: #f4f3ef;
  margin: auto;
  padding-top: 220px;
  padding-bottom: 100px;
  font-weight: 400;
}

.after__hours__area h3 {
  width: 1080px;
  max-width: 90%;
  margin: auto;
  padding-bottom: 10px;
  font-size: 22pt;
  font-weight: 200;
  line-height: 1.4;
}

.after__hours__image {
  float: right;
  padding-top: 40px;
  padding-left: 0;
  padding-right: 0;
}

.after__hours__image img {
  max-width: 600px;
  width: 100%;
  float: right;
}

.after__hours__project h2 {
  width: 300px;
  margin-bottom: 5px;
  padding-top: 100px;
  font-size: 22pt;
  font-weight: 200;
  line-height: 36px;
}

.show__desktop__hide__mobile .line {
  width: 340px;
}

.after__hours__project p {
  width: 500px;
  margin-top: 25px;
  font-size: 14pt;
  font-weight: 200;
  line-height: 34px;
}

.after__hours__help__install p {
  margin-top: 18px;
  margin-bottom: 0;
  font-size: 15px;
}

.after__hours__btn {
  padding-top: 10px;
}

.after__hours__btn a {
  height: 78px;
  color: #000;
  width: 330px;
  height: 56px;
  letter-spacing: 1px;
  border: 3px solid #000;
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px;
  font-size: 9pt;
  font-weight: 700;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.after__hours__btn a span {
  transition: all .3s;
  position: relative;
  left: 1px;
}

.after__hours__btn a:hover {
  color: #fff;
  background-color: #000;
}

.after__hours__btn a:hover span {
  left: 6px;
}

.no-decoration-link {
  color: #000;
  text-decoration: none;
}

.install__mobile, .show__mobile__hide__desktop {
  display: none;
}

.marquee__area {
  height: 50px;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  align-items: center;
  display: flex;
}

.marquee__area span {
  letter-spacing: 1px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10pt;
  font-weight: 700;
  line-height: 44px;
  position: relative;
}

.marquee__area span:after {
  content: "";
  height: 500px;
  width: 3px;
  background-color: #000;
  position: absolute;
  top: -300px;
  right: 0;
}

.common--area {
  padding-top: 110px;
  padding-bottom: 12px;
}

.common__title h2 {
  text-align: center;
  margin-bottom: 45px;
  font-size: 30pt;
  font-weight: 200;
}

.common__content__blk {
  text-align: center;
  margin-bottom: 64px;
  padding: 0 15px;
  font-size: 12pt;
  font-weight: 200;
}

.common__btn a {
  height: 78px;
  color: #000;
  width: 390px;
  height: 78px;
  letter-spacing: 1px;
  border: 3px solid #000;
  border-radius: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 40px;
  font-size: 10pt;
  font-weight: 700;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.common__btn a span {
  transition: all .3s;
  position: relative;
  left: 1px;
}

.common__btn a:hover {
  color: #fff;
  background-color: #000;
}

.common__btn a:hover span {
  left: 10px;
}

.career--area {
  padding-top: 240px;
  padding-left: 25px;
  padding-right: 25px;
}

.join__title {
  max-width: 550px;
}

.career__title h2 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 22pt;
  font-weight: 200;
}

.career__framework h2 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 16pt;
  font-weight: 200;
}

.career__framework {
  max-width: 550px;
  text-align: center;
}

.framework__content {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.career__join {
  text-align: center;
  max-width: 550px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
}

.join__content {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.join__content__holder {
  padding-left: 0;
}

.career__framework ul li {
  font-size: 14pt;
}

.meet_you__area {
  text-align: center;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
}

.careers__content__blk {
  max-width: 550px;
  margin-top: 24px;
  margin-bottom: 20px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 16pt;
  font-weight: 200;
}

.careers__framework__list {
  text-align: left;
  display: inline-block;
}

.join_team__area {
  max-width: 550px;
}

.open-roles-btn .common__btn {
  max-width: 550px;
  margin-left: 0;
  padding-left: 0;
}

.open-roles-btn .common__btn a {
  max-width: 550px;
  margin-left: 0;
}

.testimonial-parent-card {
  max-width: 1200px;
  background: #fff;
  border-radius: 44px;
  flex-shrink: 0;
  margin: 150px auto 0;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.testimonial-parent-card h2 {
  text-align: center;
  padding-bottom: 15px;
  font-size: 30px;
  font-weight: 300;
  line-height: 39px;
}

.testimonial-parent-card label {
  text-align: center;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 29px;
}

.testimonial-parent-card p {
  padding-bottom: 30px;
  font-size: 14.624px;
  line-height: 29.248px;
}

.testimonial-parent-card h4 {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 5px;
}

.testimonial-parent-card .whit-image {
  max-width: 150px;
  max-width: 150px;
  margin: 0 auto;
  position: relative;
  top: -80px;
}

.testimonial-parent-card .whit-text-container {
  margin: -64px 40px 0;
  padding: 0 0 40px;
}

@media (max-width: 768px) {
  .testimonial-parent-card {
    background: #fff;
    border-radius: 30px;
    flex-shrink: 0;
    margin: 150px auto 0;
    padding-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .testimonial-parent-card .whit-text-container {
    margin: -32px 20px 0;
    padding: 0 0 40px;
  }

  .testimonial-parent-card h2 {
    text-align: center;
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 300;
    line-height: 39px;
  }

  .testimonial-parent-card label {
    text-align: center;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
  }

  .testimonial-parent-card p {
    max-height: 100px;
    padding-bottom: 15px;
    font-size: 14.624px;
    line-height: 29.248px;
    transition: max-height .3s;
    overflow-y: scroll;
  }

  .testimonial-parent-card h4 {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 5px;
  }

  .testimonial-parent-card .whit-image {
    max-width: 100px;
    max-width: 100px;
    margin: 0 auto;
    position: relative;
    top: -60px;
  }
}

.single__slider__item {
  background: #ebece7;
  margin-left: 0;
  margin-right: 0;
}

.slider__item {
  padding-left: 0;
  padding-right: 0;
}

.slider__text {
  max-width: 40%;
  padding-top: 120px;
  padding-left: 57px;
}

.slider__text p {
  letter-spacing: 1px;
  color: #000;
  font-size: 10pt;
  font-weight: 700;
  line-height: 2;
}

.slider__text h1 {
  color: #000;
  margin-right: 12px;
  font-size: 26pt;
  font-weight: 200;
}

.owl-dots {
  display: none;
}

.slider__area {
  padding-bottom: 80px;
}

.footer__menu {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.footer__menu ul {
  align-items: right;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__menu ul li a {
  color: #000;
  margin-left: 36px;
  font-size: 24px;
  font-weight: 400;
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration-thickness: calc(1em / 6.66667);
  transition: all .7s;
  display: inline-block;
}

.footer__menu ul li a:hover {
  text-decoration: underline;
  text-decoration-thickness: calc(1em / 6.66667);
}

.footer__area {
  width: 90%;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

.footer__area .main___menu ul {
  padding-top: 35px;
}

.footer__left {
  max-width: 900px;
}

.footer__left p {
  margin-bottom: 0;
  font-size: 12pt;
  font-weight: 200;
}

.footer__menu {
  padding-top: 20px;
}

.footer__logo {
  max-width: 184px;
  margin-bottom: 16px;
}

.footer__logo img {
  draggable: false;
}

img.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale();
  -webkit-transition: -webkit-filter .2s linear;
}

img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

@media (min-width: 320px) and (max-width: 1630px) {
  .container--diff {
    max-width: 1480px;
  }
}

@media (min-width: 320px) and (max-width: 1500px) {
  .container--diff {
    max-width: 1380px;
  }
}

@media (min-width: 320px) and (max-width: 1400px) {
  .header__area {
    padding-top: 75px;
  }

  .container--diff {
    max-width: 1280px;
  }

  .main___menu ul li a {
    margin-left: 90px;
  }

  .footer__area {
    padding-bottom: 68px;
  }

  .common__btn a {
    width: 345px;
    height: 75px;
    padding: 0 36px;
  }

  .slider__area .owl-nav {
    padding: 0 60px;
  }

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1105px;
  }

  .common__content__blk {
    margin-bottom: 60px;
    padding: 0 8px;
  }
}

@media (min-width: 320px) and (max-width: 1300px) {
  .container--diff {
    max-width: 1180px;
  }

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1045px;
  }

  .main___menu ul li a {
    margin-left: 84px;
  }

  .main___menu ul {
    padding-top: 4px;
  }

  .slider__area .owl-nav button {
    font-size: 41px !important;
  }

  .slider__content h1 {
    font-size: 52px;
  }

  .slider__content {
    max-width: 1008px;
    padding-right: 40px;
  }

  .common__title h2 {
    margin-bottom: 52px;
    font-size: 42px;
  }

  .common__btn a {
    width: 330px;
    height: 73px;
    padding: 0 40px;
  }

  .common--area {
    padding-top: 100px;
    padding-bottom: 10px;
  }

  .hero__area {
    padding-top: 320px;
    padding-bottom: 230px;
  }
}

@media (min-width: 320px) and (max-width: 1200px) {
  .grow__area .right-column {
    margin: auto;
    padding-left: 70px;
  }

  .grow__area h2 {
    padding-bottom: 30px;
    font-size: 30pt;
    font-weight: 200;
  }

  .main___menu ul li a {
    margin-left: 76px;
  }

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 910px;
  }

  .header__area {
    padding-top: 60px;
  }

  .slider__area .owl-nav button {
    font-size: 36px !important;
  }

  .common__content__blk {
    margin-bottom: 50px;
    padding: 0;
  }

  .common__btn a {
    width: 305px;
    height: 68px;
    padding: 0 33px;
  }

  .common--area {
    padding-top: 90px;
    padding-bottom: 0;
  }

  .common__title h2 {
    margin-bottom: 45px;
    font-size: 37px;
  }

  .footer__area {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  .container--diff {
    max-width: 1089px;
  }

  .slider__text {
    max-width: 60%;
    padding-top: 100px;
    padding-left: 40px;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .tonic__area .space2-text {
    width: 80%;
    max-width: 80%;
    padding-top: 6.5%;
    padding-bottom: 6.5%;
    font-size: 14px;
  }

  .header__area img {
    max-width: 270px;
  }

  .credo__area ul li {
    margin-left: 3em;
    margin-right: 3em;
  }

  .grow__area .common__btn a {
    width: 305px;
    height: 62px;
    border-width: 2px;
    padding: 0 29px;
    font-size: 10pt;
  }

  .grow__area .right-column {
    margin: auto;
    padding-left: 50px;
  }

  .grow__area h2 {
    padding-bottom: 30px;
    font-size: 28pt;
    font-weight: 200;
  }

  .main___menu ul li a {
    margin-left: 55px;
  }

  .hero__area {
    padding-top: 350px;
    padding-bottom: 180px;
  }

  .hero__area p {
    padding-top: 12px;
  }

  .slider__area .owl-nav button {
    font-size: 34px !important;
  }

  .slider__area .owl-nav {
    padding: 0 32px;
  }

  .common__title h2 {
    margin-bottom: 40px;
    font-size: 32px;
  }

  .common--area {
    padding-top: 80px;
  }

  .common--area p {
    padding-left: 25px;
    padding-right: 25px;
  }

  .common--area h2 {
    padding-left: 25px;
  }

  .common__btn a {
    width: 265px;
    height: 62px;
    border-width: 2px;
    padding: 0 29px;
    font-size: 10pt;
  }

  .common__btn a span {
    left: 5px;
  }

  .common__btn a:hover span {
    left: 8px;
  }

  .footer__area {
    padding-top: 120px;
    padding-bottom: 45px;
  }

  .main___menu ul {
    padding-top: 1px;
  }

  .credo__area {
    width: 70%;
    margin: auto;
    padding-top: 180px;
    padding-bottom: 80px;
  }

  .slider__text {
    max-width: 60%;
    padding-top: 40px;
    padding-left: 20px;
  }

  .slider__text p {
    font-size: 8pt;
  }

  .slider__text h1 {
    font-size: 26pt;
  }

  .grow__area {
    width: 100%;
    margin: auto;
    padding: 60px 25px;
  }
}

@media (min-width: 320px) and (max-width: 891px) {
  .team__info__overlay {
    width: 70%;
  }

  .header__area img {
    max-width: 240px;
  }

  .credo__area ul li {
    margin-left: 1em;
    margin-right: 0;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer__menu ul li a {
    margin-left: 0;
    margin-right: 24px;
    padding-left: 0;
  }

  .footer__area {
    width: 80%;
  }

  .footer__menu {
    justify-content: left;
    align-items: left;
    margin-left: 0;
    padding-top: 12px;
    padding-left: 0;
  }

  .team__info__overlay {
    width: 100%;
  }

  .grow__area .common__btn a {
    width: 305px;
    height: 62px;
    border-width: 2px;
    padding: 0 29px;
    font-size: 10pt;
  }

  .career__framework {
    text-align: left;
    padding-top: 0;
  }

  .meet_you__area, .career__join {
    text-align: left;
  }

  .open-roles-btn {
    max-width: 620px;
    margin-left: 0;
    padding-left: 0;
  }

  .open-roles-btn .common__btn a {
    max-width: 620px;
    margin-left: 0;
    padding-left: 40px;
  }

  .grow__area .right-column {
    padding-left: 12px;
  }

  .grow__area h2 {
    padding-bottom: 30px;
    font-size: 28pt;
    font-weight: 200;
  }

  .header__area .main___menu {
    display: none;
  }

  .menu-open {
    display: block;
  }

  .hero__area {
    padding: 260px 25px 155px;
  }

  .hero__area p {
    padding-left: 0;
  }

  .slider__area .owl-nav {
    padding: 0 20px;
  }

  .slider__item {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .slider__text {
    max-width: 100%;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 37px;
  }

  .slider__text p {
    font-size: 12px;
  }

  .slider__area .owl-nav button {
    font-size: 30px !important;
  }

  .common__btn a {
    width: 252px;
    height: 56px;
    border-width: 2px;
    padding: 0 26px;
    font-size: 10pt;
  }

  .footer__left {
    text-align: left;
  }

  .footer__logo {
    max-width: 240px;
    margin-bottom: 12px;
  }

  .footer__area {
    padding-top: 100px;
    padding-bottom: 40px;
  }

  body {
    font-size: 14pt;
  }

  .credo__area, .after__hours__area {
    width: 100%;
    margin: auto;
    padding-bottom: 80px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .grow__area {
    width: 100%;
    margin: auto;
    padding: 60px 25px;
  }

  .grow__area .right-column {
    width: 100%;
    margin: auto;
    padding-top: 30px;
  }

  .investor__area {
    width: 100%;
    margin: auto;
    padding: 60px 25px;
  }

  .install__mobile {
    display: inline;
  }

  .install__desktop {
    display: none;
  }

  .show__mobile__hide__desktop {
    display: block;
  }

  .show__desktop__hide__mobile {
    display: none;
  }

  .after__hours__area h3, .after__hours__area h2, .after__hours__area p, .after__hours__area .line, .after__hours__btn {
    margin: auto;
    display: block;
    width: 80% !important;
  }

  .after__hours__area img {
    margin: auto;
    display: block;
  }

  .after__hours__btn {
    padding: 40px 0;
  }

  .after__hours__btn a {
    width: 100%;
    margin: auto;
  }

  .after__hours__area {
    margin: auto;
    padding: 150px 10px 0;
  }

  .after__hours__area h3 {
    padding-bottom: 0;
    font-size: 16pt;
    line-height: 1.65;
  }

  .after__hours__area h2 {
    padding-top: 0;
    font-size: 16pt;
    line-height: 1.65;
  }

  .after__hours__area .line {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .after__hours__project__header__mobile img {
    width: 92%;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .after__hours__help__install p {
    text-align: center;
    margin: auto auto 40px;
    font-size: 12pt;
  }

  .tonic__area {
    padding-top: 164px;
  }

  .tonic__area__content .row {
    margin-left: 0;
    margin-right: 0;
  }

  .tonic__area__content .col-md-3 {
    padding-left: 0;
    padding-right: 0;
  }

  .tonic__area .founder-description * {
    width: 100%;
  }

  .tonic__area .founder-img {
    width: 40%;
    margin-bottom: 22px;
  }

  .tonic__area hr {
    margin: 48px auto;
  }

  .tonic__area .founder-description {
    padding-bottom: 28px;
    padding-left: 0;
    padding-right: 0;
  }

  .tonic__area .tonic-images {
    padding-top: 0;
  }

  .tonic__area__content {
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .tonic__area__content .founder-section, .tonic__area hr {
    width: 100%;
  }

  .tonic__area .company-logo {
    justify-content: left;
    padding-bottom: 24px;
    padding-left: 0;
  }

  .tonic__area .image-container {
    display: block;
  }

  .tonic__area .space2-text {
    width: 80%;
    max-width: 80%;
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .career--area {
    padding-top: 200px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .credo__area p {
    font-size: 14pt;
  }

  .credo__area ul li {
    font-size: 12pt;
  }

  .header__area img {
    max-width: 240px;
  }

  .desktop-break {
    display: none;
  }

  .hero__area {
    padding: 190px 25px 40px;
  }

  .hero__area h3 {
    font-size: 29px;
  }

  .hero__area p {
    padding-top: 6px;
    padding-left: 0;
    padding-right: 0;
    font-size: 14pt;
  }

  .slider__area .owl-nav button {
    font-size: 29px !important;
  }

  .slider__area .owl-nav {
    padding: 0 13px;
  }

  .common__title h2 {
    margin-bottom: 36px;
    font-size: 30px;
  }

  .common__content__blk {
    margin-bottom: 35px;
  }

  .common__btn a {
    width: 230px;
    height: 54px;
    border-width: 2px;
    padding: 0 23px;
    font-size: 10pt;
  }

  .common--area {
    padding-top: 65px;
  }

  .footer__area {
    padding-top: 80px;
    padding-bottom: 35px;
  }

  .footer__social a {
    font-size: 28px;
  }

  .common--area p, .common--area h2 {
    text-align: left;
  }
}

/*# sourceMappingURL=responsive.css.map */
