/* Base CSS */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap');

.alignleft {
    float: left;
    margin-right: 15px;
}

.alignright {
    float: right;
    margin-left: 15px;
}

.aligncenter {
    display: block;
    margin: 0 auto 15px;
}

a:focus {
    outline: 0 solid
}

img {
    max-width: 100%;
    height: auto;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 15px;
    color: #000000;
}

body {
    color: #000000;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14pt;
    background: #FFDB9C;
    background-attachment: fixed;
}

#canvas-basic {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.selector-for-some-widget {
    box-sizing: content-box;
}

a:hover {
    text-decoration: none;
}


/*---------------------- Header area start ----------------------*/

.menu-close {
    cursor: pointer;
    transition: .4s;
}

.menu-close:hover {
    color: #888;
}

.offcanvas-area {
    position: fixed;
    right: -100%;
    height: 100%;
    width: 300px;
    background: #f7f7f7;
    z-index: 7;
    -webkit-transition: .2s;
    transition: .2s;
    visibility: hidden;
    -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 5px 5pxx rgba(0, 0, 0, 0.25);
}

.offcanvas-area hr {
    line-height: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #808080;
    height: 1px;
}

.offcanvas-area.active {
    right: 0;
    visibility: visible;
}

.offcanvas-area .offcanvas-menu-row {
    margin-left: 10pt;
    margin-bottom: 12.5pt;
    margin-top: 12.5pt;
    font-size: 18pt;
}
.offcanvas-area .offcanvas-menu a {
    color: #3D424E;
    text-decoration: underline solid transparent;
    text-decoration-thickness: 15%;
    transition: .3s;
}

.offcanvas-area .offcanvas-menu-row .menu-icon {
    float: right;
    margin-right:22px;
}

.offcanvas-area .offcanvas-menu a:hover {
    text-decoration: underline solid Currentcolor;
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
    -webkit-text-decoration-color: Currentcolor;
    text-decoration-thickness: 15%;
}

.offcanvas-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    z-index: 6;
}

.offcanvas-overlay.active {
    visibility: visible;
}

.menu-open {
    font-size: 27px;
    color: #191718;
    display: none;
    cursor: pointer;
    position: relative;
    top: 1px;
    margin-left: 20px;
    margin-right: 8px;
    transition: .3s;
}

.menu-open.hidden {
    visibility: hidden;
}

.menu-open:hover {
	color: #888;
}


.header__area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 5;
    padding-top: 85px;
    transition: all 0.3s ease-in-out;
    background-color:transparent;   
}

.header__area.active {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(30px);
    padding-top: 33px;
    padding-bottom: 33px;
}

.header__area img {
    width:auto;
    height: auto;
    max-width: 380px;
    margin-left: 25px;
    transition-duration: 200ms;
    transition: all .3s ease-in-out;
}

.header__area.active img {
    width:auto;
    height: auto;
    max-width: 184px;
}

.main___menu ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 0px;
    margin-right: 25px;
}

.main___menu ul li a {
    display: inline-block;
    margin-left: 100px;
    text-decoration: underline solid transparent;
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
    -webkit-text-decoration-color: transparent;
    font-size: 13pt;
    color: #000000;
    font-weight: 400;
    transition: .7s ease;
    text-decoration-thickness: 15%;
    -webkit-transition: .7s;    
}

.main___menu ul li a:hover {
    text-decoration: underline solid Currentcolor;
    text-decoration-thickness: 15%;
}

/*---------------------- Header area end ----------------------*/


/*---------------------- Main area start ----------------------*/

/*---------------------- Hero area start ----------------------*/

.container--diff {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
}

.hero__area {
    padding-bottom: 210px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 360px;
}

.hero__area h3 {
    font-size: 30pt;
    line-height: 1.4;
    font-weight: 200;
    padding-top: 7px;
}

.hero__area p {
    float:right;
    font-size: 16pt;
    font-weight: 300;
    padding-top: 7px;
    padding-left: 45px;
}

/*---------------------- Hero area end ----------------------*/

/*---------------------- Founder area start ----------------------*/

.founder__area {
    padding-top: 240px;
    padding-bottom: 40px;
}

.founder__area .team__image {
    width: 75%;
    float: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}

.founder__area .team__card {
    padding-bottom: 30px;
}

.founder__area .team__card__visible {
    cursor: pointer;
}

.founder__area .team__card .team__name {
    text-align: center;
    font-weight: 200;
    font-size: 18px;
    line-height: 1em;
}

.founder__area .team__card .team__title {
    text-align: center;
    line-height: 1.5em;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: .8pt;
}


.team__info__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    overflow-y:scroll;
    overflow-x:hidden;
    width: 45%;
    height: 100%;
    background: #f7f7f7;
    z-index: 7;
    transition: .2s;
    visibility: hidden;
    padding-bottom: 40px;
    -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 5px 5pxx rgba(0, 0, 0, 0.25);
}

.team__info__overlay.active {
    visibility: visible;
    right: 0px;
}

.overlay__close {
    right: 10px;
    top: 10px;
    position: fixed;
    background: white;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    padding: 0px;
    font-size: 24px;
    box-shadow: 0px 1px 5px -2pt black;

}

button {
  border-radius: 50%;
  padding: 0.5em;
  width: 30px;
  height: 30px;
  border: 2px solid blue;
  color: blue;
  position: relative;
}
button:hover {
  border: 2px solid blue;
  background-color: yellow;
  color: #ffffff;
}

button::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: blue;
  width: 2px;
  left: 12px;
  top: 5px;
  bottom: 5px;
  transform: rotate(45deg);
}
button::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: blue;
  height: 2px;
  top:12px;
  left: 5px;
  right: 5px;
  transform: rotate(45deg);
}


.team__info__overlay .team__overlay__body {
    padding-top: 30px;
    width: 90%;
    margin: auto;
}

.team__info__overlay p {
    line-height: 1.7em;
    font-size: 14px;
    font-weight: 400;
}

.team__info__overlay p.team__paragraph_emphasis {
    font-size: 18px;
}

.team__info__overlay .team__photo__overlay {
    width: 100%;
}
.team__info__overlay .team__name__overlay {
    font-weight: 200;
    font-size: 24px;
}

.team__info__overlay .team__title__overlay {
    font-weight: 800;
    font-size: 12px;
    letter-spacing: .8pt;
}

/*---------------------- Founder area end ----------------------*/

/*---------------------- Grow area start ----------------------*/

.grow__area {
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: #F4F3EF;
    padding-left:230px;
    padding-right: 200px;
}

.grow__area .common__btn a {
    align-content: right;
    margin-left: 0;
    width: 380px;
}

.grow__area p {
    font-size: 12pt;
    font-weight:200;
}

.grow__area .right-column {
    margin: auto;
    padding-left: 80px;
}

.grow__area .left-column {
    padding-right: 80px;
  display: block;
}


.grow__area h2 {
    font-size: 30pt;
    font-weight: 200;
    padding-bottom: 5px;
}

/*---------------------- Grow area end ----------------------*/

/*---------------------- Grow area start ----------------------*/

.investor__area {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #FFF;
    padding-left: 25px;
    padding-right: 25px;
}

.investor__area .right-column {
    margin: auto;
    width: 40%;
}

.investor__area ul {
    font-size: 12pt;
    font-weight: 200;
    list-style: none;
    padding-left:0px;
    line-height: 50px;
    margin-bottom: 0px;
}

.investor__area h2 {
    line-height: 1.5;
    font-size: 16pt;
    font-weight: 200;
    text-align: center;
}

/*---------------------- Grow area end ----------------------*/

/*---------------------- Credo area start ----------------------*/

.container--diff {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
}


.credo__area {
    width: 60%;
    margin: auto;
    padding-top: 240px;
    padding-bottom: 100px;
    font-weight: 200;
}

.credo__area h3 {
    font-size: 36pt;
    line-height: 1.4;
    font-weight: 200;
    padding-bottom: 1px;
}

.credo__area p {
    text-align: left;
    font-size: 20pt;
    padding-top: 7px;
}

.credo__area ul li {
    margin-left: 4em;
    margin-right: 4em;
    text-align: left;
    font-size: 14pt;
    padding-top: 10px;
}

/*---------------------- Credo area end ----------------------*/

/*---------------------- Portfolio area start ----------------------*/
.portfolio__area {
    background: #F4F3EF;
    margin: auto;
    padding-top: 270px;
    padding-bottom: 170px;
    font-weight: 400;
}

.portfolio__area__content {
    max-width:70%;
}

/*---------------------- Portfolio area end ----------------------*/

/*---------------------- Tonic area start ----------------------*/
.tonic__area {
    background: #fff;
    margin: auto;
    padding-top: 240px;
    font-weight: 400;
}

.tonic__area__content {
    max-width: 80%;
}

.tonic-images {
    padding-top: 94px;
}

.tonic__area hr {
    margin:auto;
    margin-top: 94px;
    width:90%;
    margin-bottom: 94px;
}

.founder-section {
    width:95%;
    margin:auto;
}



.image-container {
    display: flex;
    flex-direction: row;
    margin-bottom:0px;
    padding-bottom:0px;
  }

  /* Aspect ratio */
.tonic-img1 { flex: 1.011557799; }
.tonic-space2 { 
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;  
    background-color:#000;
    flex: 1.042362003;
}
.space2-text {
    max-width: 62%;
    margin: 0 auto; /* center the container horizontally */
    text-align: justify;
    font-weight: 300;
    font-size: 16px;
    line-height: 194%;
    color: #FFFFFF;
}
.space2-text span {
    display: inline-block;
}

.space2-text a {
    color: inherit;
}
.tonic-img3 { flex: 1.547770701; }
.tonic-img4 { flex: 1; }

  .image-container img {
    width: 100%;
    height: auto;
    vertical-align: middle;
    }
    

.tonic__area .founder-description * {
    width: 95%;
    margin: auto;
}
.tonic__area .founder-description h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 17px;
}

.tonic__area .founder-description h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    padding-top: 0px;
    margin-bottom: 17px;
}

.tonic__area .founder-description p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    }

.tonic__area .investors {
    padding-left: 48px;
}


.founder-img {
    width: 80%;
}

.company-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.tonic__area__content .company-logo img {
    max-width: 50%; /* to make the image responsive */
    max-height: 100%; /* to make the image responsive */
}

.tonic__area__content h2 {
    padding-top: 16px;
    font-style: normal;
    font-weight: 200;
    font-size: 28px;
    line-height: 160%;
    color: #000000;
}

.tonic__area__content .pad-right-col {
    padding-left: 1%;
    padding-right: 7%;
}

.tonic__area__content .social-info {
    padding-top: 24px;
    padding-bottom: 12px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    margin-bottom:0px;
    padding-bottom:0px;
}

.no-decoration {
    color: inherit;      /* Set the link color to the color of the surrounding text */
    text-decoration: none;/* Remove the underline */  
}

.no-decoration:hover,
.no-decoration:focus,
.no-decoration:active {
  color: inherit;      /* Set the link color on hover, focus, and active states to the color of the surrounding text */
  text-decoration: none;/* Remove the underline on hover, focus, and active states */
}


/*---------------------- Tonic area end ----------------------*/


/*---------------------- After hours area start ----------------------*/

.container--diff {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
}

.after__hours__area {
    background: #F4F3EF;
    margin: auto;
    padding-top: 220px;
    padding-bottom: 100px;
    font-weight: 400;
}

.after__hours__area h3 {
    font-size: 22pt;
    width: 1080px;
    max-width: 90%;
    margin:auto;
    line-height: 1.4;
    font-weight: 200;
    padding-bottom:10px;
}

.after__hours__image {
    padding-top: 40px;
    float:right;
    padding-left:0px;
    padding-right:0px;
}

.after__hours__image img {
    max-width:600px;
    width:100%;
    float:right;
}


.after__hours__project h2 {
    font-size: 22pt;
    width: 300px;
    line-height: 36px;
    font-weight: 200;
    padding-top: 100px;
    margin-bottom:5px;
}

.show__desktop__hide__mobile .line {
    width:340px; 
}

.after__hours__project p {
    font-size:14pt;
    margin-top:25px;
    line-height:34px;
    font-weight:200;
    width:500px;
}

.after__hours__help__install p {
    font-size:15px;
    margin-top:18px;
    margin-bottom:0;
}
.after__hours__btn {
    padding-top: 10px;
}
.after__hours__btn a {
    display: flex;
    align-items: center;
    height: 78px;
    justify-content: space-between;
    text-decoration: none;
    font-size: 9pt;
    font-weight: 700;
    color: #000;
    border: 3px solid #000;
    width: 330px;
    height: 56px;
    border-radius: 50px;
    padding: 0 28px;
    transition: .3s;
    letter-spacing: 1px;
}

.after__hours__btn a span {
    transition: .3s;
    position: relative;
    left: 1px;
}

.after__hours__btn a:hover {
    background-color: #000;
    color: #fff;
}

.after__hours__btn a:hover span {
    left: 6px;
}

.no-decoration-link {
    text-decoration: none;
    color:#000;
}

.install__mobile {
    display:none;
}

.show__mobile__hide__desktop {
    display: none;
}

/*---------------------- After hours area end ----------------------*/

/*---------------------- Marquee area start ----------------------*/
.marquee__area {
    height: 50px;
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    display: flex;
    align-items: center;
}

.marquee__area span {
    font-size: 10pt;
    font-weight: 700;
    letter-spacing: 1px;
    padding-right:20px;
    padding-left:20px;
    position: relative;
    height:100%;
    text-align: center;
    vertical-align: middle;
    line-height: 44px;
}

.marquee__area span:after {
    content: '';
    height:500px;
    position: absolute;
    right: 0;
    top: -300px;
    width:3px;
    background-color: #000;
}

/*---------------------- Marquee area end ----------------------*/

/*---------------------- Common area start ----------------------*/
.common--area {
    padding-top: 110px;
    padding-bottom: 12px;
}
.common__title h2 {
    font-size: 30pt;
    font-weight: 200;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 200;
}

.common__content__blk {
    text-align: center;
    margin-bottom: 64px;
    padding: 0 15px;
    font-weight: 200;
    font-size: 12pt;
}

.common__btn a {
    display: flex;
    align-items: center;
    height: 78px;
    justify-content: space-between;
    text-decoration: none;
    font-size: 10pt;
    font-weight: 700;
    color: #000;
    border: 3px solid #000;
    width: 390px;
    height: 78px;
    border-radius: 50px;
    padding: 0 40px;
    transition: .3s;
    margin: 0 auto;
    letter-spacing: 1px;
}

.common__btn a span {
    transition: .3s;
    position: relative;
    left: 1px;
}

.common__btn a:hover {
    background-color: #000;
    color: #fff;
}

.common__btn a:hover span {
    left: 10px;
}

/*---------------------- Common area end ----------------------*/

/*---------------------- Career area start ----------------------*/
.career--area {
    padding-top: 240px;
    padding-left: 25px;
    padding-right: 25px;
}

.join__title {
    max-width: 550px;
}

.career__title h2 {
    font-size: 22pt;
    font-weight: 200;
    font-weight: 200;
    padding-bottom:0px;
    margin-bottom:0px;
}

.career__framework h2 {
    font-size: 16pt;
    font-weight: 200;
    font-weight: 200;
    padding-bottom:0px;
    margin-bottom:0px;
}

.career__framework {
    max-width: 550px;
    text-align: center;
}

.framework__content {
    text-align:left;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.career__join {
    text-align: center;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
}

.join__content {
    text-align:left;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.join__content__holder {
    padding-left:0px;
}
.career__framework ul li{
    font-size: 14pt;
}

.meet_you__area {
    padding-top:80px;
    text-align: center;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}

.careers__content__blk {
    max-width: 550px;
    padding-bottom:0px;
    padding-top:0px;
    margin-top:24px;
    margin-bottom:20px;
    font-weight: 200;
    font-size: 16pt;
}

.careers__framework__list {
    display: inline-block;
    text-align: left;
}


.join_team__area {
    max-width: 550px;
}

.open-roles-btn .common__btn {
    max-width: 550px;
    padding-left: 0px;
    margin-left:0px;
}

.open-roles-btn .common__btn a {
    max-width: 550px;
    margin-left:0px;
}

.testimonial-parent-card {
    margin: 0 auto;
    max-width: 1200;
    border-radius: 44px;
    flex-shrink: 0;
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 150px;
    margin-right: 150px; */
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 150px;
    padding-top: 10px;
}

.testimonial-parent-card h2 {
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    line-height: 39px;
    padding-bottom: 15px;
}

.testimonial-parent-card label {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.testimonial-parent-card p {
    font-size: 14.624px;
    line-height: 29.248px;
    padding-bottom: 30px;
}

.testimonial-parent-card h4 {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 5px;
}

.testimonial-parent-card .whit-image {
    margin: 0 auto;
    position: relative;
    top: -80px;
    max-width: 150px;
    max-width: 150px;
}

.testimonial-parent-card .whit-text-container {
    margin: 0 auto;
    padding: 0;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -64px;
    padding-bottom: 40px;
    
}

@media (max-width: 768px) {
    .testimonial-parent-card {
        margin: 0 auto;
        margin-left: auto;
        margin-right: auto;
        border-radius: 30px;
        flex-shrink: 0;
        background: #FFF;
        /* margin-left: 25px;
        margin-right: 25px; */
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 150px;
        padding-top: 25px;
    }
    .testimonial-parent-card .whit-text-container {
        margin: 0 auto;
        padding: 0;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -32px;
        padding-bottom: 40px;
        
    }
    .testimonial-parent-card h2 {
        font-size: 22px;
        text-align: center;
        font-weight: 300;
        line-height: 39px;
        padding-bottom: 15px;
    }
    
    .testimonial-parent-card label {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }
    
    .testimonial-parent-card p {
        font-size: 14.624px;
        line-height: 29.248px;
        padding-bottom: 15px;
        max-height: 100px; /* Initial height to show */
        overflow-y: scroll;
        transition: max-height 0.3s ease;
    }
    
    .testimonial-parent-card h4 {
        text-align: center;
        font-size: 18px;
        font-weight: 300;
        line-height: 5px;
    }
    .testimonial-parent-card .whit-image {
        margin: 0 auto;
        position: relative;
        top: -60px;
        max-width: 100px;
        max-width: 100px;
    }

}



/*---------------------- Career area end ----------------------*/

/*---------------------- Slider area start ----------------------*/

.single__slider__item {
    background: #ebece7;
    margin-right: 0px;
    margin-left:0px;
}

.slider__item {
    padding-right:0px;
    padding-left:0px;
}

.slider__text {
    max-width: 40%;
    padding-top: 120px;
    padding-left: 57px;
}

.slider__text p {
    font-size: 10pt;
    letter-spacing: 1px;
    color: #000;
    font-weight: 700;
    line-height: 2;
}

.slider__text h1 {
    font-size: 26pt;
    color: #000;
    font-weight: 200;
    margin-right: 12px;    
}

.owl-dots {
    display: none;
}

.slider__area {
    padding-bottom: 80px;
}

/*---------------------- Footer area start ----------------------*/
.footer__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;;
    text-align: right;
  }

.footer__menu ul {
    display: flex;
    align-items: right;
    justify-content: flex-end;;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 0px;
}

.footer__menu ul li a {
    display: inline-block;
    margin-left: 36px;
    text-decoration: underline solid transparent;
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
    -webkit-text-decoration-color: transparent;
    font-size: 24px;
    color: #000000;
    font-weight: 400;
    transition: .7s ease;
    text-decoration-thickness: 15%;
    -webkit-transition: .7s;    
}

.footer__menu ul li a:hover {
    text-decoration: underline solid Currentcolor;
    text-decoration-thickness: 15%;
}


.footer__area {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 90%;
    margin:auto;
}
.footer__area .main___menu ul {
	padding-top: 35px;
}

.footer__left {
    max-width: 900px;
}


.footer__left p {
    font-size: 12pt;
    font-weight: 200;
    margin-bottom:0px;
}

.footer__menu {
    padding-top: 20px;
}

.footer__logo {
    max-width: 184px;
    margin-bottom: 16px;
}

.footer__logo img {
    draggable: false;
}

/*---------------------- Footer area end ----------------------*/

/*---------------------- Main area end ----------------------*/
/*---------------------- Utilities --------------------------*/
img.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
    -webkit-transition : -webkit-filter 200ms linear
}

img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);

}
/*---------------------- Utilities End ----------------------*/


@media (min-width: 320px) and (max-width: 1630px) {
    .container--diff {
        max-width: 1480px;
    }
}

@media (min-width: 320px) and (max-width: 1500px) {

    .container--diff {
        max-width: 1380px;
    }

}

@media (min-width: 320px) and (max-width: 1400px) {

    .header__area {
        padding-top: 75px;
    }

    .container--diff {
        max-width: 1280px;
    }

    .main___menu ul li a {
        margin-left: 90px;
    }

    .footer__area {
        padding-bottom: 68px;
    }

    .common__btn a {
        width: 345px;
        padding: 0 36px;
        height: 75px;
    }

    .slider__area .owl-nav {
        padding: 0 60px;
    }

    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1105px;
    }

    .common__content__blk {
        margin-bottom: 60px;
        padding: 0 8px;
    }

}

@media (min-width: 320px) and (max-width: 1300px) {

    .container--diff {
        max-width: 1180px;
    }

    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1045px;
    }

    .main___menu ul li a {
        margin-left: 84px;
    }

    .main___menu ul {
        padding-top: 4px;
    }

    .slider__area .owl-nav button {
        font-size: 41px !important;
    }

    .slider__content h1 {
        font-size: 52px;
    }

    .slider__content {
        max-width: 1008px;
        padding-right: 40px;
    }

    .common__title h2 {
        font-size: 42px;
        margin-bottom: 52px;
    }

    .common__btn a {
        width: 330px;
        padding: 0 40px;
        height: 73px;
    }

    .common--area {
        padding-top: 100px;
        padding-bottom: 10px;
    }

    .hero__area {
        padding-top: 320px;
        padding-bottom: 230px;
    }
}

@media (min-width: 320px) and (max-width: 1200px) {
    .grow__area .right-column {
        margin: auto;
        padding-left: 70px;
    }

    .grow__area h2 {
        font-size: 30pt;
        font-weight: 200;
        padding-bottom: 30px;
    }

    .main___menu ul li a {
        margin-left: 76px;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 910px;
    }

    .header__area {
        padding-top: 60px;
    }

    .slider__area .owl-nav button {
        font-size: 36px !important;
    }

    .common__content__blk {
        margin-bottom: 50px;
        padding: 0 0px;
    }

    .common__btn a {
        width: 305px;
        padding: 0 33px;
        height: 68px;
    }

    .common--area {
        padding-top: 90px;
        padding-bottom: 0;
    }

    .common__title h2 {
        font-size: 37px;
        margin-bottom: 45px;
    }

    .footer__area {
        padding-top: 120px;
        padding-bottom: 60px;
    }

    .container--diff {
        max-width: 1089px;
    }
    
    .slider__text {
        max-width: 60%;
        padding-top: 100px;
        padding-left: 40px;
    }
}

@media (min-width: 320px) and (max-width: 991px) {

    .tonic__area .space2-text {
        width:80%;
    }

    .tonic__area .space2-text {
        font-size: 14px;
        max-width: 80%;
        padding-top: 6.5%;
        padding-bottom: 6.5%;
    }

    .header__area img {
        max-width: 270px;
    }
    
    .credo__area ul li {
        margin-left: 3em;
        margin-right: 3em;
    }


    .grow__area .common__btn a {
        width: 305px;
        padding: 0 29px;
        height: 62px;
        font-size: 10pt;
        border-width: 2px;
    }

    .grow__area .right-column {
        margin: auto;
        padding-left: 50px;
    }

    .grow__area h2 {
        font-size: 28pt;
        font-weight: 200;
        padding-bottom: 30px;
    }

    .main___menu ul li a {
        margin-left: 55px;
    }

    .hero__area {
        padding-top: 350px;
        padding-bottom: 180px;
    }

    .hero__area p {
        padding-top: 12px;
    }

    .slider__area .owl-nav button {
        font-size: 34px !important;
    }

    .slider__area .owl-nav {
        padding: 0 32px;
    }

    .common__title h2 {
        font-size: 32px;
        margin-bottom: 40px;
    }


    .common--area {
        padding-top: 80px;
    }
    .common--area p {
        padding-left: 25px;
        padding-right: 25px;
    }
    .common--area h2 {
        padding-left: 25px;
    }
    .common__btn a {
        width: 265px;
        padding: 0 29px;
        height: 62px;
        font-size: 10pt;
        border-width: 2px;
    }

    .common__btn a span {
        left: 5px;
    }

    .common__btn a:hover span {
        left: 8px;
    }

    .footer__area {
        padding-top: 120px;
        padding-bottom: 45px;
    }

    .main___menu ul {
        padding-top: 1px;
    }

    .credo__area {
        width:70%;
        margin: auto;
        padding-top: 180px;
        padding-bottom: 80px;
    }


    .slider__text {
        max-width: 60%;
        padding-top: 40px;
        padding-left: 20px;
    }

    .slider__text p {
        font-size:8pt;
    }

    .slider__text h1 {
        font-size:26pt;
    }
    
    .grow__area {
        margin: auto;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 25px;
        padding-right: 25px;
    }

}

@media (min-width: 320px) and (max-width: 891px) {

    .team__info__overlay {
        width: 70%;
    }

    .header__area img {
        max-width: 240px;
    }
    
    .credo__area ul li {
        margin-left: 1em;
        margin-right: 0em;
    }



}
@media (min-width: 320px) and (max-width: 767px) {

    .footer__menu ul li a {
        margin-left: 0px;
        padding-left: 0px;
        margin-right: 24px;
    }

    .footer__area {
        width: 80%;
    }

    .footer__menu {
        justify-content: left;
        align-items: left;
        margin-left: 0px;
        padding-left:0px;
        padding-top:12px;
    }
    .team__info__overlay {
        width: 100%;
    }

    .grow__area .common__btn a {
        width: 305px;
        padding: 0 29px;
        height: 62px;
        font-size: 10pt;
        border-width: 2px;
    }
    
    .career__framework {
        padding-top: 0px;
        text-align: left;
    }
    .meet_you__area {
        text-align: left;
    }

    .career__join {
        text-align: left    ;
    }

    .open-roles-btn {
        max-width: 620px;
        padding-left: 0px;
        margin-left:0px;
    }

    .open-roles-btn .common__btn a {
        max-width: 620px;
        padding-left: 40px;
        margin-left:0px;
    }


    .grow__area .right-column {
        padding-left: 12px;
    }

    .grow__area h2 {
        font-size: 28pt;
        font-weight: 200;
        padding-bottom: 30px;
    }

    .header__area .main___menu {
        display: none;
    }

    .menu-open {
        display: block;
    }

    .hero__area {
        padding-bottom: 155px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 260px;
    }

    .hero__area p {
        padding-left:0px;
    }
    
    .slider__area .owl-nav {
        padding: 0 20px;
    }
    
    .slider__item {
        width:100%;
    }
    
    .slider__item {
        padding-right:0px;
        padding-left:0px;
    }

    .slider__text {
        max-width: 100%;
        width: 100%;
        padding-left: 37px;
        padding-top:40px;
        padding-bottom: 40px;
    }

    .slider__text p{
        font-size:12px;
    }

    .slider__area .owl-nav button {
        font-size: 30px !important;
    }

    .common__btn a {
        width: 252px;
        padding: 0 26px;
        height: 56px;
        font-size: 10pt;
        border-width: 2px;
    }
    
    .footer__left {
        text-align: left;
    }

    .footer__logo {
        max-width: 240px;
        margin-bottom: 12px;
    }

    .footer__area {
        padding-top: 100px;
        padding-bottom: 40px;
    }


    body {
        font-size: 14pt;
    }

    .credo__area {
        width:100%;
        padding-left:25px;
        padding-right:25px;
        margin: auto;
        padding-bottom: 80px;
    }

    .after__hours__area {
        width:100%;
        padding-left:25px;
        padding-right:25px;
        margin: auto;
        padding-bottom: 80px;
    }

    .grow__area {
        margin: auto;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .grow__area .right-column {
        padding-top: 30px;
        margin: auto;
        width: 100%;
    }

    .investor__area {
        margin: auto;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .install__mobile {
        display:inline;
    }
    .install__desktop {
        display:none;
    }
    
    .show__mobile__hide__desktop {
        display:block;
    }
    
    .show__desktop__hide__mobile {
        display:none;
    } 
    .after__hours__area h3,  .after__hours__area h2,  .after__hours__area p, .after__hours__area .line, .after__hours__btn {
        width:80% !important;
        margin:auto;
        display: block;
    }

    .after__hours__area img {
        margin:auto;
        display:block;
    }

    .after__hours__btn {
        padding-left:0px;
        padding-right:0px;
        padding-top:40px;
        padding-bottom:40px;
    }

    .after__hours__btn a {
        width:100%;
        margin:auto;
    }
    .after__hours__area {
        padding-top:150px;
        padding-left: 10px;
        padding-right:10px;
        margin:auto;
        padding-bottom:0px;
    }

    .after__hours__area h3 {
        padding-bottom:0px;
        font-size:16pt;
        line-height:1.65;
    }

    .after__hours__area h2 {
        padding-top:0px;
        font-size:16pt;
        line-height:1.65;
    }

    .after__hours__area .line {
        padding-bottom:16px;
        padding-top:16px;
    }

    .after__hours__project__header__mobile img {
        padding-top:30px;
        padding-bottom:20px;
        width:92%;
    }
    .after__hours__help__install p {
        margin:auto;
        text-align:center;
        font-size:12pt;
        margin-bottom:40px;
    }    

    .tonic__area {
        padding-top:164px;
    }

    .tonic__area__content .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .tonic__area__content .col-md-3 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .tonic__area .founder-description * {
        width:100%;
    }

    .tonic__area .founder-img {
        width: 40%;
        margin-bottom: 22px;
    }

    .tonic__area hr {
        margin: 48px auto;
    }

    .tonic__area .founder-description {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 28px;
    }

    .tonic__area .tonic-images {
        padding-top:0px;
    }
    
    .tonic__area__content {
        padding-top:10px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .tonic__area__content .founder-section {
        width:100%;
    }
    .tonic__area hr {
        width:100%;
    }
    .tonic__area .company-logo {
        padding-left:0px;
        justify-content: left;
        padding-bottom: 24px;
    }

    .tonic__area .image-container {
        display:block;
    }

    .tonic__area .space2-text {
        width:80%;
    }

    .tonic__area .space2-text {
        max-width: 80%;
        padding-top: 7.5%;
        padding-bottom: 7.5%;
    }

}

@media (min-width: 320px) and (max-width: 575px) {
    .career--area {
        padding-top: 200px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .credo__area p {
        font-size: 14pt;
    }

    .credo__area ul li {
        font-size: 12pt;
    }

    .header__area img {
        max-width: 240px;
    }

    .desktop-break { display: none; }
    
    .hero__area {
        padding: 40px 0;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 190px;
    }

    .hero__area h3 {
        font-size: 29px;
    }

    .hero__area p {
        font-size: 14pt;
        padding-top: 6px;
        padding-right: 0px;
        padding-left: 0;
    }

    .slider__area .owl-nav button {
        font-size: 29px !important;
    }

    .slider__area .owl-nav {
        padding: 0 13px;
    }
    .common__title h2 {
        font-size: 30px;
        margin-bottom: 36px;
    }


    .common__content__blk {
        margin-bottom: 35px; 
    }.common__btn a {
        width: 230px;
        padding: 0 23px;
        height: 54px;
        font-size: 10pt;
        border-width: 2px;
    }
    .common--area {
        padding-top: 65px;
    }
    .footer__area {
        padding-top: 80px;
        padding-bottom: 35px;
    }
    

    .footer__social a {
        font-size: 28px; 
    }

    .common--area p {
        text-align: left;
    }
    .common--area h2 {
        text-align: left;
    }
    
}
 